import { Link } from 'react-router-dom'
import Slider from '../components/Slider'
import rentCategoryImage from '../assets/jpg/rentCategoryImage.jpg'
import sellCategoryImage from '../assets/jpg/sellCategoryImage.jpg'
import SearchIcon from '@mui/icons-material/Search';
import {Button, Row, Col} from 'react-bootstrap';
import { toast } from 'react-toastify'
// import countries from 'countries.js'
import { useState } from 'react'

var countries = [
  {"name": "Algeria", "code": "DZ", "flag": "🇩🇿"},  
  {"name": "Angola", "code": "AO", "flag": "🇦🇴"}, 
  {"name": "Botswana", "code": "BW", "flag": "🇧🇼"}, 
  {"name": "Burkina Faso", "code": "BF", "flag": "🇧🇫"}, 
  {"name": "Cameroon", "code": "CM", "flag": "🇨🇲"}, 
  {"name": "Central African Republic", "code": "CF", "flag": "🇨🇫"}, 
  {"name": "Chad", "code": "TD", "flag": "🇹🇩"}, 
  {"name": "Comoros", "code": "KM", "flag": "🇰🇲"}, 
  {"name": "Congo", "code": "CG", "flag": "🇨🇬"}, 
  {"name": "Congo, The Democratic Republic of the", "code": "CD", "flag": "🇨🇩"}, 
  {"name": "Cote D\"Ivoire", "code": "CI", "flag": "🇨🇮"}, 
  {"name": "Djibouti", "code": "DJ", "flag": "🇩🇯"}, 
  {"name": "Egypt", "code": "EG", "flag": "🇪🇬"}, 
  {"name": "Equatorial Guinea", "code": "GQ", "flag": "🇬🇶"}, 
  {"name": "Eritrea", "code": "ER", "flag": "🇪🇷"}, 
  {"name": "Ethiopia", "code": "ET", "flag": "🇪🇹"}, 
  {"name": "Gabon", "code": "GA", "flag": "🇬🇦"}, 
  {"name": "Gambia", "code": "GM", "flag": "🇬🇲"}, 
  {"name": "Ghana", "code": "GH", "flag":   "🇬🇭"}, 
  {"name": "Guinea", "code": "GN", "flag": "🇬🇳"}, 
  {"name": "Guinea-Bissau", "code": "GW", "flag": "🇬🇼"},  
  {"name": "Kenya", "code": "KE", "flag": "🇰🇪"}, 
  {"name": "Liberia", "code": "LR", "flag": "🇱🇷"}, 
  {"name": "Libyan Arab Jamahiriya", "code": "LY", "flag": "🇱🇾"},
  {"name": "Madagascar", "code": "MG", "flag":  "🇲🇬"},
  {"name": "Malawi", "code": "MW", "flag": "🇲🇼"},
  {"name": "Mali", "code": "ML", "flag": "🇲🇱"},
  {"name": "Mauritania", "code": "MR", "flag":  "🇲🇷"}, 
  {"name": "Mauritius", "code": "MU", "flag": "🇲🇺"},
  {"name": "Morocco", "code": "MA", "flag": "🇲🇦"}, 
  {"name": "Mozambique", "code": "MZ", "flag": "🇲🇿"},
  {"name": "Namibia", "code": "NA", "flag": "🇳🇦"},
  {"name": "Niger", "code": "NE", "flag": "🇳🇪"},
  {"name": "Nigeria", "code": "NG", "flag": "🇳🇬"},
  {"name": "Sao Tome and Principe", "code": "ST", "flag": "🇸🇹"},
  {"name": "Senegal", "code": "SN", "flag": "🇸🇳"},
  {"name": "Seychelles", "code": "SC", "flag": "🇸🇨"},
  {"name": "Sierra Leone", "code": "SL", "flag": "🇸🇱"},
  {"name": "Somalia", "code": "SO", "flag": "🇸🇴"},
  {"name": "South Africa", "code": "ZA", "flag": "🇿🇦"},
  {"name": "Sudan", "code": "SD", "flag": "🇸🇩"},
  {"name": "Tanzania, United Republic of", "code": "TZ", "flag": "🇹🇿"},
  {"name": "Togo", "code": "TG", "flag": "🇹🇬"},
  {"name": "Tunisia", "code": "TN", "flag": "🇹🇳"},
  {"name": "Uganda", "code": "UG", "flag": "🇺🇬"},
  {"name": "Zambia", "code": "ZM", "flag": "🇿🇲"},
  {"name": "Zimbabwe", "code": "ZW", "flag": "🇿🇼"}
]
function Explore() {
  const [value, setValue] = useState('')
  const [shownResults, setShownResults] = useState(countries);
  const [showResults, setShowResults] = useState(false);
  const [toUrl, setToUrl] = useState('')
  const [category, setCategory] = useState('buy')

  const handleChange = (e) => {
    setValue(e.target.value);
    const results = countries.filter(country => country.name.toLowerCase().includes(e.target.value.toLowerCase()));
    setShownResults(results);
    setShowResults(true);
    setToUrl(`cat/${category}`);
    
  } 

  const handleSearch = (e) => {
    e.preventDefault();
    setShowResults(false);
    setValue('');
   //Only if the value is not empty
    
if(!e.value){
  toast.error('Please enter a valid country')
}
else{
    setShowResults(false);
    setValue('');

    if (!isValidCountry(e.value.toLowerCase())) {
      toast.error('Please enter a valid country')
    }
    else{
     
      window.location.href = {toUrl}

    }
  }
  }
function isValidCountry(country) {
  return countries.some(c => c.name.toLowerCase() === country)
}
 
  function resultsList (){
    if(showResults){
      return (
        <div className="results-list" >
          {shownResults.map(country => (
            <div className="result-item" key={country.name}>
              <Link to={`/${toUrl}/${country.name}`}>
                <div className="result-item-name">
                  {country.name+" "+ country.flag}
                </div>
              </Link>
            </div>
          ))}
        </div>
      )
    }
  }

 function changeCategory (category){
   setCategory(category)
   console.log(category);
   
 }
  
let Google_Places_API_Key = 'AIzaSyCCqSyV5jwj1elr_ND6WtQoyOzZZzsQvFY';
let Google_Places_API_URL = 'https://maps.googleapis.com/maps/api/place/autocomplete/json';


  



  return (
    <div className='explore' style={{textAlign:"center"}}>
      <header>
        <p className='pageHeader'>Afrivest</p>
      </header>


      <main>
        {/* <Slider /> */}

       
        {/* Put two buttons on the same line */}
        <div className='buttonContainer'>
        { 
        category=="rent" && 
        <div>
        <Button onClick={()=>changeCategory("rent")} style={{ backgroundColor:"yellow"}}className='button'>
          Rent
          </Button>

          <Button style={{}}  onClick={()=>changeCategory("buy")} className='button'>
            Buy
          </Button>
          </div>}

          { 
        category=="buy" && 
        <div>
        <Button  onClick={()=>changeCategory("rent")} style={{}}className='button'>
            Rent
          </Button>

          <Button   onClick={()=>changeCategory("buy")} style={{backgroundColor:"yellow"}} className='button'>
 Buy
          </Button>
          </div>}
          
        </div>

      <div>
      <form>


    <input type="text" onChange={(e)=>handleChange(e)} value={value} name="name" style={{width:"700px", marginBottom:"30px", borderColor:"none" , borderRadius:"20px", height:"50px"}}/>
 {resultsList()}
  <Button style={{width:"50px", height:"50px", backgroundColor:"white"}} onClick={(e)=>handleSearch(e)}>

<SearchIcon style={{color:"black"}} sx={{fontSixe:70}}/>
</Button>
</form>
      </div>
        {/* <div className='exploreCategories'>
          <Link to='/category/rent'>
            <img
              src={rentCategoryImage}
              alt='rent'
              className='exploreCategoryImg'
            />
            <p className='exploreCategoryName'>Places for rent</p>
          </Link>
          <Link to='/category/sale'>
            <img
              src={sellCategoryImage}
              alt='sell'
              className='exploreCategoryImg'
            />
            <p className='exploreCategoryName'>Places for sale</p>
          </Link>
        </div> */}
      </main>
    </div>
  )
}

export default Explore
