import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyABL4Z2Z6-JfINRomOo3QVc3hj-ofuB1LU",
  authDomain: "afrivest-4165d.firebaseapp.com",
  projectId: "afrivest-4165d",
  storageBucket: "afrivest-4165d.appspot.com",
  messagingSenderId: "1033345722680",
  appId: "1:1033345722680:web:a9edbac7fc27cc72edf9cd",
  measurementId: "G-C9RB397C70"
}

// Initialize Firebase
initializeApp(firebaseConfig)
export const db = getFirestore()
